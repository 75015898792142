<template>
  <div>
    <vs-popup class="holamundo " :title="$t('businessEventsList.proposalForm.sendProposal')" :active.sync="isActive" @close="closePopup">
        <div v-if="currentBusinessEvent">
          <div class="flex flex-wrap pt-2">
            <!-- <vs-input type="text" :label="$t('businessEventsList.proposalForm.name')" v-model="proposal.name" class="md:w-1/2 w-full mb-3 pr-2" autocomplete="off"/> -->
            <vs-input type="number" :label="$t('businessEventsList.proposalForm.price')" v-model="proposal.price" class="w-full mb-3 pr-2" autocomplete="off"/>
            <vs-textarea :label="$t('businessEventsList.proposalForm.details')" height="150px" v-model="proposal.details" class="w-full mb-3 mr-2"/>
            <div class="vx-col w-full mb-2 pl-2">
              <div class="container mt-2">
                <div class="vx-row">
                  <label class="v-select-label">{{$t('businessEventsList.proposalForm.attachments')}}</label>
                </div>
                <div class="vx-row">
                    <input ref="attachments" type="file" multiple="multiple" class="w-full" />
                </div>
              </div>
            </div>
            <div class="vx-row w-full mb-5 justify-end">
              <vs-button icon="check" color="success" class="text-sm" @click="submitForm">{{$t('send')}}</vs-button>

            </div>
            
        </div>
            
        </div>
    </vs-popup>

  </div>
</template>

<script>

export default {

  props: {
    sendProposalIsActive: false,
    currentBusinessEvent: {
        type: Object,
        defult: null
    },

  },
  data () {
    return {
      proposal: {}
    }
  },
  computed: {
    isActive: {
      get() {
        return this.sendProposalIsActive
      },
      set(newState) {
        return newState
      }
    },
    currentVenueId () {
      return this.$store.getters.currentVenueId
    },
    currentVenue () {
      return this.$store.getters.currentVenue? JSON.parse(this.$store.getters.currentVenue): {};
    },
  },
  created () {
  },
  methods: {
    closePopup() {
      this.$emit('update:sendProposalIsActive', false);
    },
    submitForm() {
      this.$store.dispatch('loader/loaderOn')
      this.proposal.attachments = this.$refs.attachments.files;
      
      this.$store.dispatch('businessEvents/sendProposal',{businessEventId: this.currentBusinessEvent.id,venueId: this.currentVenueId, proposal: this.proposal})
        .then((response) => {
          this.proposal = {};
          this.$refs.attachments.value = null;
          let message = "تم ارسال العرض بنجاح"

          this.closePopup();

          this.$emit('openPreview', this.currentBusinessEvent);

          this.$vs.notify({
              color:'success',
              title: this.$t('editsDone'),
              text: response.data.success_message || message
          })
        })
        .catch((error) => {})
        .finally(()=>{this.$store.dispatch('loader/loaderOff')},this);
    }

  }
}
</script>