<template>
  <div>
    <vs-popup class="holamundo " :title="$t('businessEventsList.businessEventDetails')" :active.sync="isActive" @close="closePopup">
        <div v-if="currentBusinessEvent">
          <vs-table stripe :data="fakeData">
            <template>
              <vs-tr>
                <vs-td :sortable="false">{{$t('businessEventsList.name')}}</vs-td>
                <vs-td>{{currentBusinessEvent.event_name}}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td >{{$t('businessEventsList.guestsCount')}}</vs-td>
                <vs-td>{{currentBusinessEvent.guests_count}}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td >{{$t('businessEventsList.eventDescription')}}</vs-td>
                <vs-td>{{currentBusinessEvent.event_description}}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td >{{$t('businessEventsList.eventLocation')}}</vs-td>
                <vs-td>{{currentBusinessEvent.event_location}}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td >{{$t('businessEventsList.date')}}</vs-td>
                <vs-td>{{currentBusinessEvent.event_date}}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td >{{$t('businessEventsList.budget')}}</vs-td>
                <vs-td>{{currentBusinessEvent.budget.toLocaleString()}} {{$t('SAR')}}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td >{{$t('businessEventsList.status')}}</vs-td>
                <vs-td>
                  <vs-chip :color="currentBusinessEvent.status.color" class="product-order-status"><p>{{ currentBusinessEvent.status.display_name }}</p></vs-chip>
                </vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td >{{$t('businessEventsList.attachments')}}</vs-td>
                <vs-td>
                  <div class="vx-row px-2 py-5 flex">
                    <div class="vx-col py-1 px-1" v-for="(attachment, index) in currentBusinessEvent.attachments" :key="attachment">
                      <vs-button color="warning" type="filled" size="small" @click="attachmentBtnClicked(attachment)">{{$t('businessEventsList.attachment')}} {{index+1}}</vs-button>
                    </div>
                  </div>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <h4 class="mt-10">{{$t('businessEventsList.proposals')}}</h4>
          <div class="grid lg:grid-cols-2 md:grid-cols-1 gap-3 mb-5">
            <div class="flex-none w-20" v-for="proposal in proposals" :key="proposal.id">
              <vs-card class="cardx my-5" fixed-height>
                <div slot="extra-content">
                  <div class="container h-full">
                    <div class="vx-row px-2 py-3 h-32 overflow-auto hover:overflow-scroll">
                        <div class="vx-col w-full">
                            <small class="font-medium">{{proposal.details}}</small>
                        </div>
                    </div>
                    <div class="vx-row px-2 py-5 flex">
                        <div class="vx-col py-1 px-1" v-for="(attachment, index) in proposal.attachments" :key="attachment">
                            <vs-button color="warning" type="filled" size="small" @click="attachmentBtnClicked(attachment)">{{$t('businessEventsList.attachment')}} {{index+1}}</vs-button>
                        </div>
                    </div>
                  </div>
                </div>
                <div slot="footer">
                  <div class="vx-row flex justify-end px-5">
                    <span>{{proposal.price ? proposal.price.toLocaleString() : ''}} {{$t('SAR')}}</span>
                  </div>
                </div>
              </vs-card>
            </div>
            <div class="flex-none w-20">
              <vs-card class="cardx my-5" fixed-height>
                <div slot="extra-content">
                  <vs-button class="w-full h-full" color="success" type="border" icon="add" @click="addProposal"></vs-button>
                </div>
              </vs-card>
            </div>
          </div>
            
        </div>
    </vs-popup>

  </div>
</template>

<script>

export default {

  props: {
    previewIsActive: false,
    currentBusinessEvent: {
        type: Object,
        defult: null
    },

  },
  data () {
    return {
      fakeData:[{"id": 1,"name": "Fake Data",},], // fake data, vs-table wont work without data prop passed
    }
  },
  computed: {
    isActive: {
      get() {
        return this.previewIsActive
      },
      set(newState) {
        return newState
      }
    },
    currentVenueId () {
      return this.$store.getters.currentVenueId
    },
    currentVenue () {
      return this.$store.getters.currentVenue? JSON.parse(this.$store.getters.currentVenue): {};
    },
    baseURL: () => process.env.VUE_APP_IMAGES_BASE_URL,
    proposals () {
      return this.$store.getters.proposals
    },
  },
  created () {
  },
  methods: {
    closePopup() {
        this.$emit('update:previewIsActive', false);
    },
    attachmentBtnClicked(attachment) {
      window.open(`${this.baseURL}/${attachment}`, '_blank');
    },
    getProposals() {
      this.$store.dispatch('loader/loaderOn')
      this.$store.dispatch('businessEvents/getProposals',{businessEventId: this.currentBusinessEvent.id,venueId: this.currentVenueId})
      .finally(()=>{this.$store.dispatch('loader/loaderOff')},this);
    },
    addProposal() {
      this.$emit('sendProposal', false);
      this.closePopup();
    }

  },
  watch: {
    isActive(newVal) {
      if(newVal) {
        this.getProposals();
      }
    },
  }

}
</script>

<style>
.vs-card--footer {
  width: 100%;
}
.vs-card-extra--content, .vs-card-extra--content > div {
  height: 100%;
  min-height: 120px;
}
</style>
