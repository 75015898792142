<template>
  <div>
    <!-- BUSINESS EVENT CONFIRMATION POPUP -->
    <business-event-confirmation :confirmationIsActive.sync="businessEventConfirmationIsActive" :currentBusinessEvent.sync="currentBusinessEvent" @openPreview="showData" @getBusinessEvents="getBusinessEvents"/>
    <!-- BUSINESS EVENT PREVIEW POPUP -->
    <business-event-preview :previewIsActive.sync="businessEventPreviewIsActive" :currentBusinessEvent.sync="currentBusinessEvent" @sendProposal="showSendProposal" />
    <!-- SEND PROPOSAL FORM POPUP -->
    <send-proposal-form-popup :sendProposalIsActive.sync="sendProposalIsActive" :currentBusinessEvent.sync="currentBusinessEvent" @openPreview="showData"/>



    <vs-table
        class="mb-5"
        :sst="true"
        ref="businessEventsTable"
        @change-page="handleChangePage"
        v-model="selected"
        pagination
        :total="parseInt(tableConfig.total)"
        :max-items="parseInt(tableConfig.perPage)"
        :data="businessEvents"
        @selected="showData"
        >

        <template  slot="thead">
            <template>
                <vs-th v-for="(col, index) in generalInfoColumnsTitles" :key="index" :sort-key="col.sortKey">
                <p class="table-head font-medium truncate">{{ col.title }}</p>
                </vs-th>
            </template>
        </template>

        <template slot-scope="{data}">
            <tbody>
            <template>
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <p class="product-name font-medium truncate">{{tr.event_name}}</p>
                  </vs-td>
                  <vs-td>
                    <p class="booking-date truncate">{{tr.event_date}}</p>
                  </vs-td>
                  <vs-td>
                    <p class="booking-date truncate">
                      {{tr.budget.toLocaleString()}} {{$t('SAR')}}
                    </p>
                  </vs-td>

                  <vs-td class="whitespace-no-wrap">
                    <span :style="{background: tr.status.color}" style="padding: 4px 10px; color: white; border-radius: 100px">
                      {{tr.status.display_name || 'غير محدد'}}
                    </span>
                  </vs-td>


                </vs-tr>
            </template>

            </tbody>
        </template>

    </vs-table>
  </div>
</template>

<script>
import BusinessEventConfirmation from '@/views/components/qosoor-components/BusinessEvents/BusinessEventConfirmation.vue'
import BusinessEventPreview from '@/views/components/qosoor-components/BusinessEvents/BusinessEventPreview.vue'
import SendProposalFormPopup from '@/views/components/qosoor-components/BusinessEvents/SendProposalFormPopup.vue'
export default {
  components: {
    BusinessEventConfirmation,
    BusinessEventPreview,
    SendProposalFormPopup
  },
  data () {
    return {
        selected: [],
        generalInfoColumnsTitles : [
            {title: this.$t('businessEventsList.name'), key: "name"},
            {title: this.$t('businessEventsList.date'), key: "date"},
            {title: this.$t('businessEventsList.budget'), key: "budget"},
            {title: this.$t('businessEventsList.status'), key: "status"},
        ],
        tableConfig: {
            total: 2,
            perPage: 10,
            currentPage: 1,
        },
        currentBusinessEvent: null,
        businessEventConfirmationIsActive: false,

        businessEventPreviewIsActive: false,

        sendProposalIsActive: false
        
    }
  },
  computed: {
    isActive: {
      get() {
        return this.businessEventConfirmationIsActive
      },
      set(newState) {
        return newState
      }
    },
    currentVenueId () {
      return this.$store.getters.currentVenueId
    },
    businessEvents () {
      return this.$store.getters.businessEvents ? this.$store.getters.businessEvents : [];
    },

  },
  methods: {
    getBusinessEvents(page = this.tableConfig.currentPage) {
      let query = {
        page: page,
        perPage: this.tableConfig.perPage
      }
      
      this.$store.dispatch('loader/loaderOn')
      this.$store.dispatch('businessEvents/getBusinessEvents',{venueId: this.currentVenueId, params: query})
      .then((response) => {
          this.tableConfig.total = response.data.total
      })
      .catch((error) => {})
      .finally(()=>{this.$store.dispatch('loader/loaderOff')},this);
    },
    handleChangePage(page) {
      this.tableConfig.currentPage = page;
      this.getBusinessEvents(page);
    },
    showData (data) {
        // first, send open business event request
        this.currentBusinessEvent = data
        if(data.status.key == 'agentInterested' || data.status.key == 'proposalSent') {
          this.businessEventPreviewIsActive = true;
          this.getBusinessEvents(this.tableConfig.currentPage);
        } else {
          let params = {
            "status": "openedByAgent"
          }
  
          this.$store.dispatch('loader/loaderOn')
          this.$store.dispatch('businessEvents/changeStatus',{businessEventId: this.currentBusinessEvent.id,venueId: this.currentVenueId, params: params})
          .then((response) => {
            this.currentBusinessEvent = response.data;
            this.getBusinessEvents(this.tableConfig.currentPage);
            this.businessEventConfirmationIsActive = true
          })
          .catch((error) => {})
          .finally(()=>{this.$store.dispatch('loader/loaderOff')},this);
        }
    },
    showSendProposal() {
      this.sendProposalIsActive = true;
    }
  },
  created () {
    this.getBusinessEvents();
  }
}
</script>

<style>

</style>