<template>
  <div>
    <vs-popup class="holamundo " title="" :active.sync="isActive" @close="closePopup">
        <div v-if="currentBusinessEvent">
            <div class="vx-col p-0 w-full flex flex-wrap justify-between mb-5">
                <vs-button icon="check" color="success" class="w-2/3 text-sm" @click="updateStatusClicked('agentInterested')">{{ $t("interested")}}</vs-button>
                <vs-button icon="block" type="border" color="danger" class="w-1/4" @click="updateStatusClicked('agentNotInterested')">{{ $t("notInterested")}}</vs-button>
            </div>

            <div class="vx-col p-0 w-full mb-8">
                <vs-alert color="warning" icon="note" class="h-full text-center lg:text-left">
                    {{$t('pages.businessEventConfirmationDetails')}}
                </vs-alert>
            </div>


            <div class="vx-col px-5 w-full flex flex-wrap lg:justify-between justify-center">
                <h3 class="opacity-75 my-2 text-center lg:my-0 lg:text-right">
                  {{currentBusinessEvent.event_name}}
                </h3>

                <h3 v-if="currentBusinessEvent.budget">
                  {{currentBusinessEvent.budget.toLocaleString()}} {{$t('SAR')}}
                </h3>
            </div>

            <div class="vx-col p-0 w-full flex flex-wrap justify-center">
                <hr class="row w-11/12 mt-3" style="opacity: 0.5">
            </div>

            <div class="px-5 mt-5 w-full">
              {{currentBusinessEvent.event_description}}
            </div>

            <div class="vx-col p-0 w-full flex flex-wrap justify-center">
                <hr class="row w-11/12 mt-3" style="opacity: 0.5">
            </div>
            <div class="flex justify-between px-5 mt-5">
              <span class="text-sm mt-2">{{ $t("businessEventsList.contactUsNote")}}</span>
              <vs-button icon-pack="feather" icon="icon-message-circle" color="success" type="border" class="text-sm" @click="contactViaWhatsapp">{{ $t("businessEventsList.contactUs")}}</vs-button>
            </div>
        </div>
    </vs-popup>

  </div>
</template>

<script>

export default {

  props: {
    confirmationIsActive: false,
    currentBusinessEvent: {
        type: Object,
        defult: null
    },
  },
  data () {
    return {
    }
  },
  computed: {
    isActive: {
      get() {
        return this.confirmationIsActive
      },
      set(newState) {
        return newState
      }
    },
    currentVenueId () {
      return this.$store.getters.currentVenueId
    },
    currentVenue () {
      return this.$store.getters.currentVenue? JSON.parse(this.$store.getters.currentVenue): {};
    },
  },
  created () {
  },
  methods: {
    closePopup() {
      this.$emit('update:confirmationIsActive', false);
    },
    updateStatusClicked(status){
      let params = {
        "status": status
      }
      this.$store.dispatch('businessEvents/changeStatus',{businessEventId: this.currentBusinessEvent.id,venueId: this.currentVenueId, params: params})
        .then((response) => {
          this.currentBusinessEvent.status = response.data.status;

          let message = status == "agentInterested" ?  "تم تسجيل اهتمامك بنجاح." : "تم تسجيل عدم اهتمامك."

          this.closePopup();
          if(status == 'agentInterested') {
              this.$emit('openPreview', this.currentBusinessEvent);
          } else {
              this.$emit('getBusinessEvents');
          }
          this.$vs.notify({
              color:'success',
              title: this.$t('editsDone'),
              text:response.success_message || message
          })
        })
        .catch((error) => {})
        .finally(()=>{this.$store.dispatch('loader/loaderOff')},this);


    },
    contactViaWhatsapp() {
      let venueName = this.currentVenue.arabic_name;
      let requestNumber = this.currentBusinessEvent.request_number;

      window.open(`https://wa.me/966505457219?text=السلام عليكم، أنا من ${venueName} وأتواصل بخصوص طلب الاعمال ${requestNumber}`, '_blank');
    },
  }

}
</script>

<style>

</style>
